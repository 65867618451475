@font-face {
  font-family: 'EverettRegular';
  src: url('./fonts/Everett-Regular-web.woff2') format('woff2'),
      url('./fonts/Everett-Regular-web.woff') format('woff'),
      url('./fonts/Everett-Regular-web.ttf') format('truetype');
}

@font-face {
  font-family: 'EverettMedium';
  src: url('./fonts/Everett-Medium-web.woff2') format('woff2'),
      url('./fonts/Everett-Medium-web.woff') format('woff'),
      url('./fonts/Everett-Medium-web.ttf') format('truetype');
}

html,
body {
  margin: 0;
  font-family: 'EverettRegular', sans-serif;
  background: #FAFAFA;
}

html{
  height: 100%;
}

.jejIjy {
    display: grid;
    padding-left: 8px;
    padding-top: 8px;
    grid-template-columns: repeat(12, 1fr);
    grid-column: auto / span undefined;
    grid-template-rows: auto;
    gap: 4px;
}

.ctPyOM{
    display: grid;
    grid-template-columns: auto;
    grid-column: auto / span 12;
    grid-template-rows: auto;
    gap: 0rem;
}



.gBmNQV {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column: auto / span undefined;
    grid-template-rows: auto;
    gap: 1px;
}

.imdidV {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column: auto / span undefined;
    grid-template-rows: auto;
    gap: 4px;
}

.eVibBF {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column: auto / span undefined;
    grid-template-rows: auto;
    gap: 10px;
}

.dHMeqK {
    display: flex;
    flex: 1 1 0%;
    flex-wrap: nowrap;
    overflow-x: hidden;
}

.ePqktn .em-ds-tabs-panels__panel {
    padding-top: 15px;
}

.iJYhig {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column: auto / span undefined;
    grid-template-rows: auto;
    gap: 7px;
}

.rAGMc {
    display: grid;
    grid-template-columns: auto;
    grid-column: auto / span 6;
    grid-template-rows: auto;
    gap: 20px;
}

.kYULFk {
    display: grid;
    grid-template-columns: auto;
    grid-column: auto / span 4;
    grid-template-rows: auto;
    gap: 0rem;
}

.gUnlas {
    padding: 0px 0px 5px 5px;
    position: relative;
    top: -8px;
    background: #FAFAFA;
    width: 110px;
}

.ctPyOM {
    display: grid;
    grid-template-columns: auto;
    grid-column: auto / span 12;
    grid-template-rows: auto;
    gap: 0rem;
}

.cjDUoc {
    display: grid;
    grid-template-columns: auto;
    grid-column: auto / span 2;
    grid-template-rows: auto;
    gap: 0rem;
}

.bQYKjs {
    display: grid;
    grid-template-columns: auto;
    grid-column: auto / span 2;
    grid-template-rows: auto;
    gap: 0rem;
}

.gkmilQ {
    display: grid;
    grid-template-columns: auto;
    grid-column: auto / span 6;
    grid-template-rows: auto;
    gap: 5px;
}

.eZlWFT {
    grid-column: auto / span 6;
    border-left: 1px solid rgb(169, 177, 190);
    padding-left: 5px;
    height: 112%;
}

.coborrower_box{
    border-left: 1px solid rgb(169, 177, 190);
    padding-left: 5px;
}

.borrowerPairAddressContainer {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column: auto / span undefined;
    grid-template-rows: auto;
    gap: 10px;
}

.borrowerPairAddressContainerWrapper {
    display: grid;
    grid-template-columns: auto;
    grid-column: auto / span 20;
    grid-template-rows: auto;
    gap: 5px;
}

.borrowerRow{
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    gap: 0rem;
}

.row1{
    grid-row: 1;
}

.row2{
    grid-row: 2;
}

.row3{
    grid-row: 3;
}

.col1{
    grid-column: 1
}

.col2{
    grid-column: 2
}

.col3{
    grid-column: 3
}

.col4{
    grid-column: 4
}

.col5{
    grid-column: 5
}


.col1_span5{
    grid-column: 1 / span 5;
}

.col1_span4{
    grid-column: 1 / span 4;
}

.col2_span2{
    grid-column: auto / span 2;
}

.col2_span4{
    grid-column: auto / span 4;
}

.col3_span3{
    grid-column: auto / span 3;
}

.col4_span3{
    grid-column: auto / span 3;
}

.col5_span2{
    grid-column: auto / span 2;
}

.borrowerRow1col1 {
    grid-column: auto / span 5;
    grid-row: 1;
}

.borrowerRow2col1 {
    grid-column: auto / span 5;
    grid-row: 2;
}

.borrowerRow3col1 {
    grid-column: auto / span 5;
    grid-row: 3;
}

.kYULFk {
    display: grid;
    grid-template-columns: auto;
    grid-column: auto / span 4;
    grid-template-rows: auto;
    gap: 0rem;
}

.borrower_coborrower_row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column: auto / span undefined;
    grid-template-rows: auto;
    gap: 5px;
}

.eaFdIo {
    display: grid;
    grid-template-columns: auto;
    grid-column: auto / span 3;
    grid-template-rows: auto;
    gap: 0rem;
}

.bQYKjs {
    display: grid;
    grid-template-columns: auto;
    grid-column: auto / span 2;
    grid-template-rows: auto;
    gap: 0rem;
}

.fSbuWM {
    display: grid;
    grid-template-columns: auto;
    grid-column: auto / span 5;
    grid-template-rows: auto;
    gap: 0rem;
}

.requiredMark{
    bottom: 0.30769rem;
    color: #c64252;
    font-size: .75rem;
    margin-left: 0.30769rem;
    position: relative;
}

body {
    min-height: 100%;
    max-width: 100vw;
}

#root{
    min-height: 100%;
}

span {
  font-size: 14px;
  line-height: 12px;
}

.cWOmil {
    float: right;
    margin: 0px 10px 10px;
}

.cWOmil .em-ds-button {
    display: inline-flex;
    margin: 0px 5px;
}

.ffdUYw {
    font-size: 16px;
    font-weight: 600;
    margin: 0px;
}

.css-62g3xt-dummyInput {
    background: 0px center;
    border: 0px;
    font-size: inherit;
    outline: 0px;
    padding: 0px;
    width: 1px;
    color: transparent;
    left: -100px;
    opacity: 0;
    position: relative;
    transform: scale(0);
}

.mainInfoRow{
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column: auto / span undefined;
    grid-template-rows: auto;
    gap: 10px;
}

.kHNLAa .em-ds-label {
    padding-bottom: 3px;
}

.em-ds-input{
    margin: 0 0;
}

.ePqktn {
    padding-top: 10px;
    max-width: 100%;
}

.em-ds-combobox-container input[type="text"]:disabled {
    background-color: #e9ecef !important;
}

.rw-widget-picker{
    min-height: 30px;
}

.hSxjYL{
    color: rgb(198, 66, 82);;
}

.creditBox{
    padding: 0;
}

.creditPanel > .css-19kzrtu{
    padding: 0;
}


element.style {
    text-align: center;
}
.dataTable th {
    border: 0px !important;
    border-right: 0.07692rem solid #ebedf0 !important;
}


.dataTable td {
    border: 0px !important;
    background-color: rgba(0,0,0,0);
}

.dataTable thead{
    border-bottom: 0.07692rem solid #cbcfd7 !important;
}

.dataTable th {
    border-bottom: 0.07692rem solid #ebedf0 !important;
}

.rc-table-cell.rc-table-cell-row-hover {
    background-color: rgba(0,0,0,0.05) !important;
}

.rc-table td{
    border-bottom: 1px solid rgba(1,1,1,0.1) !important;
}

.rc-table-content {
    border: 1px solid #f6f7f9 !important;;
    border-radius: 0 !important;
    -ms-box-shadow: 1px 1px 3px 1px rgba(0,0,0,.3) !important;
    -o-box-shadow: 1px 1px 3px 1px rgba(0,0,0,.3) !important;
    box-shadow: 1px 1px 3px 1px rgba(0,0,0,.3) !important;
}

.rc-table th, .rc-table td {
    padding: 6px 8px !important;
}

.rc-table th {
    padding-top: 12px !important;
}

.loader-wrapper{
    position: fixed !important;
    width: 100%;
    height: 100%;
}

.app-container{
    position: fixed !important;
    width: 100%;
    height: 100%;
}

.em-ds-button{
    border: 1px solid #c2c2c2;
}

.white-button{
    background-color: white !important;
}

.refreshing-button{
    display: inline-flex;
    margin-right: 20px;
    margin-bottom: -10px;
}

.refreshing-label{
    margin-top: 18px;
    margin-left: 10px;
}
.refreshing-spinner{
    margin-top: 8px;
}

.status-wrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 4px;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}

.status-wrapper-failed{
    cursor: pointer;
}

.status-icon{
    background-image: url("images/icons/ic_info_small.png");

    display: flex;
    flex-direction: column;

    width: 16px;
    height: 16px;
}

.status-icon-completed{
    background-image: url("images/icons/ic_check.png");

    display: flex;
    flex-direction: column;

    width: 16px;
    height: 16px;
}

.status-icon-failed{
    background-image: url("images/icons/ic_error.png");

    display: flex;
    flex-direction: column;

    width: 16px;
    height: 16px;
}

.status-name{
    font-size: 12px;
    line-height: 18px;
    /* identical to box height, or 150% */

    text-transform: uppercase;

    color: #676767;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}

.status-name-failed{
    font-size: 12px;
    line-height: 18px;
    /* identical to box height, or 150% */

    text-decoration-line: underline;
    text-transform: uppercase;

    /* Brand and Product/Sky */

    color: #5E84FF;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.status-error-message{
    position: absolute;
    width: 400px;
    /*height: 100%;*/
    left: -140px;
    top: -40px;

    background: #FFFFFF;
    box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.13);
    /*transform: matrix(-1, 0, 0, 1, 0, 0);*/
}

.status-error-message-text{
    /*position: absolute;*/
    /*width: 400px;*/
    /*height: 72px;*/
    /*left: 20px;*/
    /*top: 10px;*/

    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    /* or 150% */

    padding: 10px;
    /* Foundation/Iron */
    cursor: pointer;
    color: #4C4C4C;
}

.status-wrapper-failed .status-error-message {
  display: none;
}

.status-wrapper-failed-hover .status-error-message {
  display: block !important;
}
